
  import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: { ProgressCard: () => import('./ProgressCard.vue') },
})
  export default class CollapsedItems extends Vue {
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
  @Prop({ type: String, default: 'mdi-view-grid-plus-outline' }) readonly icon!: string;
  @Prop({ type: String, default: 'More' }) readonly title!: string;
  @Prop({ type: Number, default: 0 }) readonly total!: number;
  @Prop({ type: Number, default: 0 }) readonly amount!: number;
  }
